import SSORedirectPage from "@/authentication/redirect/SSORedirectPage"
import UnstyledRoute from "@/core/route/unstyled-route/UnstyledRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Route } from "react-router-dom"
import GoodStandingRequiredRoute from "./permissioned-route/GoodStandingRequiredRoute"

const LoginPage = lazyWithRetry(
  () => import(/* webpackChunkName: "login-page" */ "@/authentication/login/LoginPage")
)

const LoginWithLinkPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "login-with-link-page" */ "@/authentication/login-with-link/LoginWithLinkPage"
    )
)

const LoginWithTokenPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "login-with-token-page" */ "@/authentication/login-with-token/LoginWithTokenPage"
    )
)

const ForgotPasswordPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "forgot-password-pag" */ "@/authentication/forgot-pasword/ForgotPasswordPage"
    )
)

const SetPasswordPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "set-password-page" */ "@/authentication/set-password/SetPasswordPage"
    )
)

const LogoutPage = lazyWithRetry(
  () => import(/* webpackChunkName: "logout-page" */ "@/authentication/logout/LogoutPage")
)

const RedirectPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "redirect-page" */ "@/authentication/redirect/RedirectPage"
    )
)

const TokenExchangePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "token-exchange-page" */ "@/authentication/login/token-exchange/TokenExchangePage"
    )
)

const EmailSignUpPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "email-sign-up-page" */
      "@/authentication/email-sign-up/EmailSignUpPage"
    )
)

const EmailVerificationSentPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "email-verification-sent-page" */ "@/authentication/email-verification-page/EmailVerificationSentPage"
    )
)

const EmailVerificationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "email-verification-page" */ "@/authentication/email-verification-page/EmailVerificationPage"
    )
)

const NotificationOptOutPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "email-notification-opt-out-page" */ "@/notification/opt-out-page/NotificationOptOutPage"
    )
)

const JoinCommunityPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "join-community-page" */ "@/authentication/join-community/JoinCommunityPage"
    )
)

const MembershipPlanSelectionPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "membership-plan-selection-page" */ "@/membership-plan/page/MembershipPlanSelectionPage"
    )
)

const ContentSubscriptionOutOutPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "content-subscription-opt-out-page" */ "@/notification/opt-out-page/ContentSubscriptionOptOutPage"
    )
)

const GuestEntityPage = lazyWithRetry(
  () => import(/* webpackChunkName: "guest-entity-page" */ "@/guest/GuestEntityPage")
)

function getPublicRouteComponents() {
  return [
    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.LOGIN}`}
      path={ROUTE_NAMES.AUTHENTICATION.LOGIN}
      exact
    >
      <LoginPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.LOGIN_WITH_LINK}`}
      path={ROUTE_NAMES.AUTHENTICATION.LOGIN_WITH_LINK}
      exact
    >
      <LoginWithLinkPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.LOGIN_WITH_TOKEN}`}
      path={ROUTE_NAMES.AUTHENTICATION.LOGIN_WITH_TOKEN}
      exact
    >
      <LoginWithTokenPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.FORGOT_PASSWORD}`}
      path={ROUTE_NAMES.AUTHENTICATION.FORGOT_PASSWORD}
      exact
    >
      <ForgotPasswordPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.RESET_PASSWORD}`}
      path={ROUTE_NAMES.AUTHENTICATION.RESET_PASSWORD}
      exact
    >
      <SetPasswordPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.CHANGE_PASSWORD}`}
      path={ROUTE_NAMES.AUTHENTICATION.CHANGE_PASSWORD}
      exact
    >
      <SetPasswordPage />
    </Route>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.LOGOUT}`}
      path={ROUTE_NAMES.AUTHENTICATION.LOGOUT}
      exact
    >
      <LogoutPage />
    </UnstyledRoute>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.SOCIAL_AUTH_REDIRECT}`}
      path={ROUTE_NAMES.AUTHENTICATION.SOCIAL_AUTH_REDIRECT}
      exact
    >
      <RedirectPage />
    </UnstyledRoute>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.TOKEN_EXCHANGE}`}
      path={ROUTE_NAMES.AUTHENTICATION.TOKEN_EXCHANGE}
      exact
    >
      <TokenExchangePage />
    </UnstyledRoute>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.SSO_AUTH}`}
      path={ROUTE_NAMES.AUTHENTICATION.SSO_AUTH}
      exact
    >
      <SSORedirectPage />
    </UnstyledRoute>,

    // Sign-Up Flow
    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SIGN_UP}`}
      path={ROUTE_NAMES.ONBOARDING.SIGN_UP}
      exact
    >
      <EmailSignUpPage />
    </UnstyledRoute>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.VERIFY_EMAIL_SENT}`}
      path={ROUTE_NAMES.AUTHENTICATION.VERIFY_EMAIL_SENT}
      exact
    >
      <EmailVerificationSentPage />
    </UnstyledRoute>,

    <UnstyledRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.VERIFY_EMAIL}`}
      path={ROUTE_NAMES.AUTHENTICATION.VERIFY_EMAIL}
      exact
    >
      <EmailVerificationPage />
    </UnstyledRoute>,

    <GoodStandingRequiredRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.VERIFY_ADMIN_INVITATION}`}
      path={ROUTE_NAMES.AUTHENTICATION.VERIFY_ADMIN_INVITATION}
      unstyled={true}
      exact
    >
      <JoinCommunityPage />
    </GoodStandingRequiredRoute>,

    <Route
      key={`route:=${ROUTE_NAMES.NOTIFICATION_OPT_OUT}`}
      path={ROUTE_NAMES.NOTIFICATION_OPT_OUT}
      exact
    >
      <NotificationOptOutPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.CONTENT_OPT_OUT}`}
      path={ROUTE_NAMES.CONTENT_OPT_OUT}
      exact
    >
      <ContentSubscriptionOutOutPage />
    </Route>,

    <GoodStandingRequiredRoute
      key={`route:=${ROUTE_NAMES.COMMUNITY.JOIN.ROOT}`}
      path={ROUTE_NAMES.COMMUNITY.JOIN.ROOT}
    >
      <JoinCommunityPage />
    </GoodStandingRequiredRoute>,

    <GoodStandingRequiredRoute
      key={`route:=${ROUTE_NAMES.COMMUNITY.MEMBERSHIPS.ROOT}`}
      path={ROUTE_NAMES.COMMUNITY.MEMBERSHIPS.ROOT}
      exact
    >
      <MembershipPlanSelectionPage />
    </GoodStandingRequiredRoute>,

    <GoodStandingRequiredRoute
      key={`route:=${ROUTE_NAMES.COMMUNITY.GUEST.DETAIL}`}
      path={ROUTE_NAMES.COMMUNITY.GUEST.DETAIL}
      exact
    >
      <GuestEntityPage />
    </GoodStandingRequiredRoute>,
  ]
}

export default getPublicRouteComponents

/**
 * @generated SignedSource<<8ab40141da4ae44623b8a062fda0cff4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProductsAdminLearnModeContextQuery$variables = {
  domain: string;
};
export type ProductsAdminLearnModeContextQuery$data = {
  readonly domain: {
    readonly organization: {
      readonly productMemberships: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly isLearnMode: boolean;
            readonly product: {
              readonly id: string;
              readonly chatChannels: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                  };
                }>;
              };
            };
          };
        }>;
      };
    };
  } | null;
};
export type ProductsAdminLearnModeContextQuery = {
  variables: ProductsAdminLearnModeContextQuery$variables;
  response: ProductsAdminLearnModeContextQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "domain",
    "variableName": "domain"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isLearnMode",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "isViewerMembership",
      "value": true
    }
  ],
  "concreteType": "ProductMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "productMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembership",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isLearnMode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChatChannelNodeConnection",
                  "kind": "LinkedField",
                  "name": "chatChannels",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ChatChannelNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ChatChannel",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "productMemberships(isLearnMode:true,isViewerMembership:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsAdminLearnModeContextQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": "DomainMapping",
        "kind": "LinkedField",
        "name": "domainMapping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductsAdminLearnModeContextQuery",
    "selections": [
      {
        "alias": "domain",
        "args": (v1/*: any*/),
        "concreteType": "DomainMapping",
        "kind": "LinkedField",
        "name": "domainMapping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27a8e37ce242eb846b7fda5660e756b4",
    "id": null,
    "metadata": {},
    "name": "ProductsAdminLearnModeContextQuery",
    "operationKind": "query",
    "text": "query ProductsAdminLearnModeContextQuery(\n  $domain: String!\n) {\n  domain: domainMapping(domain: $domain) {\n    organization {\n      productMemberships(isViewerMembership: true, isLearnMode: true) {\n        edges {\n          node {\n            id\n            isLearnMode\n            product {\n              id\n              chatChannels {\n                edges {\n                  node {\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "500b3f22381ac383b4685866280a4976";

export default node;

import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  OnboardingChecklistContextCompleteItemMutation,
  OnboardingChecklistItemKind,
} from "@/core/context/__generated__/OnboardingChecklistContextCompleteItemMutation.graphql"
import {
  OnboardingChecklistContextQuery,
  OnboardingChecklistContextQuery$data,
} from "@/core/context/__generated__/OnboardingChecklistContextQuery.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import usePermissions from "@utils/hook/usePermissions"
import { createContext, useCallback, useContext, useState } from "react"
import { graphql } from "relay-runtime"
import { RequireOnlyOne } from "stream-chat"

type OnboardingChecklistSectionNode = NodeFromConnection<
  NonNullable<OnboardingChecklistContextQuery$data["node"]>["onboardingChecklistSections"]
>

type OnboardingChecklistItemNode = NodeFromConnection<
  OnboardingChecklistSectionNode["items"]
>

type OnboardingChecklistContextValue = {
  activeState: OnboardingChecklistActiveState
  setActiveSectionId: (id: string | null) => void
  setActiveItemId: (id: string | null) => void
  completionPercentage: number
  nextIncompleteItem: OnboardingChecklistItemNode | null
  sections: OnboardingChecklistSectionNode[]
  isChecklistItemCompleted: (kind: OnboardingChecklistItemKind) => boolean
  completeChecklistItem: (
    args: RequireOnlyOne<{
      id?: GlobalID
      kind?: OnboardingChecklistItemKind
    }>
  ) => Promise<void>
}

const OnboardingChecklistContext = createContext<OnboardingChecklistContextValue>({
  activeState: {
    sectionId: null,
    itemId: null,
  },
  setActiveSectionId: () => null,
  setActiveItemId: () => null,
  completionPercentage: 0,
  sections: [],
  nextIncompleteItem: null,
  isChecklistItemCompleted: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  completeChecklistItem: async () => {},
})

export function useOnboardingChecklistContext() {
  const context = useContext(OnboardingChecklistContext)

  if (context === null) {
    throw new Error(
      "useOnboardingChecklistContext must be used within a OnboardingChecklistProvider"
    )
  }

  return context
}

type OnboardingChecklistActiveState = {
  sectionId: string | null
  itemId: string | null
}

function OnboardingChecklistProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const activeOrganization = useActiveOrganization()
  const permissions = usePermissions(activeOrganization)
  const { onboardingChecklist } = useFeatureFlags()

  const { node } = Relay.useSkippableLazyLoadQuery<OnboardingChecklistContextQuery>(
    graphql`
      query OnboardingChecklistContextQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            onboardingChecklistSections {
              totalCount
              edges {
                node {
                  id
                  ...AdminOnboardingChecklistSectionFragment
                  items {
                    edges {
                      node {
                        id
                        content {
                          kind
                          name
                        }
                        completedAt
                        organizationOnboardingChecklistSectionId
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
    },
    {
      // Skip fetching the checklist if the user does not have the required permission to view it
      //   to speed up the initial render
      skip: !permissions?.has("onboarding_checklist.read"),
    }
  )

  const form = useFormStore<OnboardingChecklistContextCompleteItemMutation>(
    graphql`
      mutation OnboardingChecklistContextCompleteItemMutation(
        $input: CompleteOrganizationOnboardingChecklistItemInput!
      ) {
        response: completeOrganizationOnboardingChecklistItem(input: $input) {
          node {
            id
            completedAt
            ...AdminOnboardingChecklistItemTemplateFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationOnboardingChecklistItemId: "",
    }
  )

  const orderedSections = Relay.connectionToArray(node?.onboardingChecklistSections)
  // Flattened list of all sections' items ordered by their position in the checklist
  // This makes it easier to find the next incomplete item
  // Example: [section1.item1, section1.item2, section2.item1, section2.item2, ...]
  const orderedItems = orderedSections.flatMap((section) =>
    Relay.connectionToArray(section.items)
  )
  const completionPercentage =
    (orderedItems.filter((item) => item.completedAt).length / orderedItems.length) * 100
  // Find the next incomplete item in the checklist
  const nextIncompleteItem = orderedItems.find((item) => !item.completedAt) || null

  // Active state of the onboarding checklist
  const [activeState, setActiveState] = useState<OnboardingChecklistActiveState>(() => ({
    // Auto-expand the first incomplete section and item on initial render
    sectionId: nextIncompleteItem?.organizationOnboardingChecklistSectionId || null,
    itemId: nextIncompleteItem?.id || null,
  }))

  /**
   *  Set the active (organization) onboarding checklist section id in the context
   */
  const setActiveSectionId = useCallback(
    (id: string | null) => {
      // When a section is active, auto-expand the first incomplete item in the section
      const nextActiveItem = id
        ? orderedItems.find(
            (item) =>
              item.organizationOnboardingChecklistSectionId === id && !item.completedAt
          )
        : null

      setActiveState({
        sectionId: id,
        itemId: nextActiveItem ? nextActiveItem.id : null,
      })
    },
    [orderedItems]
  )

  /**
   * Set the active (organization) onboarding checklist item id in the context
   */
  const setActiveItemId = useCallback(
    (id: string | null) => {
      const activeItem = orderedItems.find((item) => item.id === id)

      setActiveState((prevState) => ({
        sectionId: activeItem
          ? activeItem.organizationOnboardingChecklistSectionId
          : prevState.sectionId,
        itemId: activeItem ? activeItem.id : null,
      }))
    },
    [orderedItems]
  )

  /**
   * Check if item of a given kind is completed
   */
  const isChecklistItemCompleted = useCallback(
    (kind: OnboardingChecklistItemKind) => {
      const item = orderedItems.find((i) => i.content.kind === kind)

      if (!item) return false

      return Boolean(item.completedAt)
    },
    [orderedItems]
  )

  /**
   * Complete an onboarding checklist item either by ID or kind.
   */
  const completeChecklistItem = useCallback(
    async ({
      id,
      kind,
    }: RequireOnlyOne<{
      id?: GlobalID
      kind?: OnboardingChecklistItemKind
    }>) => {
      // Do nothing if the onboarding checklist FF is not enabled
      if (!onboardingChecklist) return

      await form.submit({
        organizationOnboardingChecklistItemId: id,
        kind,
      })
    },
    [form, onboardingChecklist]
  )

  return (
    <OnboardingChecklistContext.Provider
      value={{
        activeState,
        sections: orderedSections,
        setActiveItemId,
        setActiveSectionId,
        completionPercentage,
        nextIncompleteItem,
        isChecklistItemCompleted,
        completeChecklistItem,
      }}
    >
      {children}
    </OnboardingChecklistContext.Provider>
  )
}

export default OnboardingChecklistProvider

/**
 * @generated SignedSource<<fca10c4fec0cc42d6f7299b8e6b42b9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type UpdateProductMembershipInput = {
  id: string;
  isHiddenFromNav?: boolean | null;
  role?: ProductRole | null;
  isLearnMode?: boolean | null;
};
export type ProductsAdminLearnModeContext_UpdateLearnModeMutation$variables = {
  input: UpdateProductMembershipInput;
};
export type ProductsAdminLearnModeContext_UpdateLearnModeMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly isLearnMode: boolean;
      readonly product: {
        readonly id: string;
        readonly chatChannels: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            };
          }>;
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type ProductsAdminLearnModeContext_UpdateLearnModeMutation = {
  variables: ProductsAdminLearnModeContext_UpdateLearnModeMutation$variables;
  response: ProductsAdminLearnModeContext_UpdateLearnModeMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProductMembershipResponse",
    "kind": "LinkedField",
    "name": "updateProductMembership",
    "plural": false,
    "selections": [
      {
        "alias": "node",
        "args": null,
        "concreteType": "ProductMembership",
        "kind": "LinkedField",
        "name": "productMembership",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLearnMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatChannelNodeConnection",
                "kind": "LinkedField",
                "name": "chatChannels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatChannelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChatChannel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsAdminLearnModeContext_UpdateLearnModeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductsAdminLearnModeContext_UpdateLearnModeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "39906d460fa8934db5df55c24dc6dea4",
    "id": null,
    "metadata": {},
    "name": "ProductsAdminLearnModeContext_UpdateLearnModeMutation",
    "operationKind": "mutation",
    "text": "mutation ProductsAdminLearnModeContext_UpdateLearnModeMutation(\n  $input: UpdateProductMembershipInput!\n) {\n  response: updateProductMembership(input: $input) {\n    node: productMembership {\n      id\n      isLearnMode\n      product {\n        id\n        chatChannels {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e282eac869b2cc7f01f523a3e8e0ec47";

export default node;

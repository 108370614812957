/**
 * @generated SignedSource<<edf66df9ee0ce342b0be3afea2dbacbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductsAdminLearnModeContext_ProductMembershipFragment$data = {
  readonly id: string;
  readonly isLearnMode: boolean;
  readonly product: {
    readonly id: string;
    readonly chatChannels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
  };
  readonly " $fragmentType": "ProductsAdminLearnModeContext_ProductMembershipFragment";
};
export type ProductsAdminLearnModeContext_ProductMembershipFragment$key = {
  readonly " $data"?: ProductsAdminLearnModeContext_ProductMembershipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductsAdminLearnModeContext_ProductMembershipFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductsAdminLearnModeContext_ProductMembershipFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLearnMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelNodeConnection",
          "kind": "LinkedField",
          "name": "chatChannels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannelNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChatChannel",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};
})();

(node as any).hash = "71183670ca5e113f4042c6f3a91662a8";

export default node;

import { ProductsAdminLearnModeContextQuery } from "@/core/context/__generated__/ProductsAdminLearnModeContextQuery.graphql"
import { ProductsAdminLearnModeContext_UpdateLearnModeMutation } from "@/core/context/__generated__/ProductsAdminLearnModeContext_UpdateLearnModeMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { createContext, ReactNode, useContext, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface ProductsAdminLearnModeProviderProps {
  children: ReactNode
}

export function ProductsAdminLearnModeProvider({
  children,
}: ProductsAdminLearnModeProviderProps) {
  const { domain } = useLazyLoadQuery<ProductsAdminLearnModeContextQuery>(
    graphql`
      query ProductsAdminLearnModeContextQuery($domain: String!) {
        domain: domainMapping(domain: $domain) {
          organization {
            productMemberships(isViewerMembership: true, isLearnMode: true) {
              edges {
                node {
                  ...ProductsAdminLearnModeContext_ProductMembershipFragment
                    @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    { domain: window.location.hostname }
  )

  const productMembershipsWithLearnMode = Relay.connectionToArray(
    domain?.organization?.productMemberships
  )

  // Store the fragment into this state
  const [productMemberships, setProductMemberships] = useState<
    Record<
      GlobalID,
      { isLearnMode: boolean; productId: GlobalID; chatChannelIds: Set<GlobalID> }
    >
  >(
    productMembershipsWithLearnMode.reduce<
      Record<
        GlobalID,
        { isLearnMode: boolean; productId: GlobalID; chatChannelIds: Set<GlobalID> }
      >
    >((acc, pm) => {
      acc[pm.id] = {
        isLearnMode: pm.isLearnMode,
        productId: pm.product.id,
        chatChannelIds: new Set(
          pm.product.chatChannels.edges.map((edge) => edge.node.id)
        ),
      }
      return acc
    }, {})
  )

  const updateProductMembershipForm =
    useFormStore<ProductsAdminLearnModeContext_UpdateLearnModeMutation>(
      graphql`
        mutation ProductsAdminLearnModeContext_UpdateLearnModeMutation(
          $input: UpdateProductMembershipInput!
        ) {
          response: updateProductMembership(input: $input) {
            node: productMembership {
              ...ProductsAdminLearnModeContext_ProductMembershipFragment
                @relay(mask: false)
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        id: "",
        isLearnMode: null,
      }
    )

  return (
    <ProductsAdminLearnModeContext.Provider
      value={{
        isViewerInLearnModeForProduct,
        isViewerInLearnModeForChatChannel,
        turnOnLearnModeForProduct,
        turnOffLearnModeForProduct,
        removeProductMembershipFromState,
        addProductMembershipToState,
        isLoading: updateProductMembershipForm.isSubmitting,
      }}
    >
      {children}
    </ProductsAdminLearnModeContext.Provider>
  )

  function isViewerInLearnModeForProduct(productId: GlobalID) {
    for (const pmKey of Object.keys(productMemberships)) {
      const pm = productMemberships[pmKey]
      if (pm.productId === productId) {
        return pm.isLearnMode
      }
    }

    return false
  }

  function isViewerInLearnModeForChatChannel(chatChannelId: GlobalID) {
    for (const pmKey of Object.keys(productMemberships)) {
      const pm = productMemberships[pmKey]
      if (pm.chatChannelIds.has(chatChannelId)) {
        return pm.isLearnMode
      }
    }

    return false
  }

  async function updateProductMembershipLearnMode(
    productMembershipId: GlobalID,
    isLearnMode: boolean
  ) {
    const { response, didSave } = await updateProductMembershipForm.submit({
      id: productMembershipId,
      isLearnMode,
    })
    if (!didSave || !response?.node) return

    if (response.node.isLearnMode) {
      setProductMemberships((prev) => ({
        ...prev,
        [response.node!.id]: {
          isLearnMode: response.node!.isLearnMode,
          productId: response.node!.product.id,
          chatChannelIds: new Set(
            response.node!.product.chatChannels.edges.map((edge) => edge.node.id)
          ),
        },
      }))
    } else {
      setProductMemberships((prev) => {
        const { [response.node!.id]: _, ...rest } = prev
        return rest
      })
    }

    displaySuccessToast({
      message: isLearnMode ? `Now in Learn Mode` : `No longer in Learn Mode`,
    })
  }

  async function turnOnLearnModeForProduct(productMembershipId: GlobalID) {
    await updateProductMembershipLearnMode(productMembershipId, true)
  }

  async function turnOffLearnModeForProduct(productMembershipId: GlobalID) {
    await updateProductMembershipLearnMode(productMembershipId, false)
  }

  function removeProductMembershipFromState(productMembershipId: GlobalID) {
    setProductMemberships((prev) => {
      const { [productMembershipId]: _, ...rest } = prev
      return rest
    })
  }

  function addProductMembershipToState(
    productMembershipId: GlobalID,
    {
      productId,
      isLearnMode,
      chatChannelIds,
    }: { productId: GlobalID; isLearnMode: boolean; chatChannelIds: GlobalID[] }
  ) {
    setProductMemberships((prev) => ({
      ...prev,
      [productMembershipId]: {
        isLearnMode,
        productId,
        chatChannelIds: new Set(chatChannelIds),
      },
    }))
  }
}

export type ProductsAdminLearnModeContextValue = {
  isViewerInLearnModeForProduct: (productId: GlobalID) => boolean
  isViewerInLearnModeForChatChannel: (chatChannelId: GlobalID) => boolean
  turnOnLearnModeForProduct: (productMembershipId: GlobalID) => void
  turnOffLearnModeForProduct: (productMembershipId: GlobalID) => void
  removeProductMembershipFromState: (productMembershipId: GlobalID) => void
  addProductMembershipToState: (
    productMembershipId: GlobalID,
    {
      productId,
      isLearnMode,
      chatChannelIds,
    }: { productId: GlobalID; isLearnMode: boolean; chatChannelIds: GlobalID[] }
  ) => void
  isLoading: boolean
}

const ProductsAdminLearnModeContext =
  createContext<ProductsAdminLearnModeContextValue | null>(null)

export const useProductsAdminLearnMode = (): ProductsAdminLearnModeContextValue => {
  const context = useContext(ProductsAdminLearnModeContext)
  return context!
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ProductsAdminLearnModeContext_ProductMembershipFragment on ProductMembership {
    id
    isLearnMode
    product {
      id
      chatChannels {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`

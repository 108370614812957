/** A context that exposes some commonly used details about the current organization. */

import { PreloadedProviderProps } from "@/core/context/ContextProviders"
import { OrganizationRole } from "@/core/context/__generated__/ActiveOrganizationContextFragment.graphql"
import RetryableErrorOverlayFallback from "@/core/error/RetryableErrorOverlayFallback"
import { AppWithContextProvidersPreloadedQuery } from "@/core/root-app/AppWithContextProvidersQuery"
import {
  AppWithContextProvidersQuery,
  AppWithContextProvidersQuery$data,
} from "@/core/root-app/__generated__/AppWithContextProvidersQuery.graphql"
import { sendSentryAnException } from "@/core/sentryHandler"
import { isViewingAsMember } from "@/product/util/hook/useInitViewAsMember"
import usePermissions from "@utils/hook/usePermissions"
import React, { useContext } from "react"
import { Helmet } from "react-helmet-async"
import { graphql, usePreloadedQuery } from "react-relay"

export type ActiveOrganizationContextValue = NonNullable<
  AppWithContextProvidersQuery$data["domain"]
>["organization"] & {
  viewerRole: OrganizationRole | undefined
  viewerIsOwnerOrAdmin: boolean
  isAdminViewingAsMember: boolean
  viewerPermissions: Set<string>
}

const ActiveOrganizationContext =
  React.createContext<ActiveOrganizationContextValue | null>(null)

export function useActiveOrganization() {
  return useContext(ActiveOrganizationContext)
}

export const ActiveOrganizationProvider: React.FC<PreloadedProviderProps> = (props) => {
  const { domain } = usePreloadedQuery<AppWithContextProvidersQuery>(
    AppWithContextProvidersPreloadedQuery,
    props.queryRef
  )

  const organization = domain?.organization
  const viewerPermissions = usePermissions(organization)

  // If we are not on the base domain and missing the organization then
  // we should show an error. Most of these will be caused by a query error
  // so we want the user to be able to retry
  if (window.location.origin !== BASE_DOMAIN_URL && !organization) {
    const err = new Error("Missing activeOrganization")
    console.error(err)
    sendSentryAnException(err, {
      extra: {
        title: "activeOrganizationContext",
      },
    })

    return <RetryableErrorOverlayFallback />
  }

  const role = organization?.viewerMembership?.role
  const isOwnerOrAdmin = role === "owner" || role === "admin"
  const viewingAsMember = isViewingAsMember()

  return (
    <ActiveOrganizationContext.Provider
      value={
        organization
          ? {
              ...organization,
              viewerRole: role,
              viewerIsOwnerOrAdmin: isOwnerOrAdmin && !viewingAsMember,
              isAdminViewingAsMember: isOwnerOrAdmin && viewingAsMember,
              viewerPermissions,
            }
          : null
      }
    >
      {organization?.faviconUrl && (
        <Helmet>
          <link rel={"icon"} href={organization.faviconUrl} />
        </Helmet>
      )}
      {organization?.name && (
        <Helmet>
          <meta property={"og:site_name"} content={organization.name} />
        </Helmet>
      )}
      {props.children}
    </ActiveOrganizationContext.Provider>
  )
}

export default ActiveOrganizationProvider

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ActiveOrganizationContextFragment on Organization {
    id
    name
    slug
    timezone
    currency
    cover
    logo
    darkLogo
    faviconUrl
    description
    hasZoomIntegration
    hasStripeConnection
    hasZapierIntegration
    hasSlackConnection
    slackConnectionStatus
    slackConnection {
      version
    }
    stripeAccountId
    isDmEnabled
    isChannelsEnabled
    isProductsEnabled
    isExploreProductTabHidden
    isEventsEnabled
    showGlobalTabTitles
    primaryDomain
    billingContactEmail
    visibility
    googleAnalyticsId
    facebookPixelId
    googleTagManagerId
    creationDatetime
    viewerMembership {
      id
      role
      hasInstructorOrManagerRole
      organizationMembershipSlackConnection {
        id
      }
      hasSeenOnboarding
      streamChatUserId
    }
    badge {
      kind
      icon
      color
      emoji
      mediaUrl
    }
    ...CommunityBadgeFragment
    dashboard {
      id
      layout
    }
    defaultMembershipPlan {
      id
    }
    viewerMembershipPlan {
      id
      viewerCanChangeMembershipPlan {
        value
        message
      }
    }
    subscription {
      status
    }
    viewerActiveMembershipPlan {
      id
    }
    authProvider {
      connectionId
      allowsJitProvisioning
      status
    }
    isMemberOnboardingRequired
    isChatBotEnabled
    hasBackfilledStreamMessages
    checkoutVersion
    ...useHasEntitlementActiveOrganizationFragment
    ...CustomThemeProviderActiveOrganizationFragment
    ...usePermissionsFragment
    ...useCommunityLandingPagesFragment
    ...LabelsContextActiveOrganizationFragment
    ...useSubscriptionStandingActiveOrganizationFragment
    ...CommunityBadgeFragment
    ...UserflowContextActiveOrganizationFragment
    ...KoalaContextActiveOrganizationFragment
    ...useSearchClientFragment
    ...useChatBotFragment
    ...useCopilotBotFragment
  }
`
